import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Navbar, Nav, NavItem, NavDropdown } from "react-bootstrap";

const Header = ({ siteTitle }) => (

  <div
    style={{
      backgroundColor: "#fff",
      position: "fixed",
      width: "100%",
      height: "5rem",
      marginTop: "-5rem",
      zIndex: 10000,
    }}
  >
    <Navbar bg="light" variant="light" expand="lg" fixed="top">
      <Navbar.Brand as={Link} to="/">
        {siteTitle}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarColor03" />
      <Navbar.Collapse id="navbarColor03">
        <Nav className="navbar-nav me-auto">
          <NavItem>
            <Nav.Link as={Link} activeClassName="active" to="/recipes">
              Recipes
            </Nav.Link>
          </NavItem>
          <NavItem>
            <Nav.Link as={Link} activeClassName="active" to="/tags">
              Tags
            </Nav.Link>
          </NavItem>
          {/*<NavDropdown title="Others" id="others">
            <NavDropdown.Item as={Link} to="/about">
              About
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/equipments">
              Equipments
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/blog">
              Blog
            </NavDropdown.Item>
          </NavDropdown>*/}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </div>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;